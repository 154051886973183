import React from "react";
import { NewCookingEventProvider } from "../contexts";
import { Layout, SEO, CreateForm } from "../components";

const CreatePage = () => {
  return (
    <NewCookingEventProvider>
      <Layout>
        <SEO title="Create" />
        <CreateForm />
      </Layout>
    </NewCookingEventProvider>
  );
};

export default CreatePage;
